<template>
  <v-container style="margin-top:10%">
    <v-row align="start" justify="center">
      <v-col cols="12">
        <v-card class="mx-auto" max-width="500">
          <v-layout justify-center>
            <v-img
              align="center"
              justify="center"
              :src="
                require(`@/assets/${this.$store.getters.getLogo.path}/logo.png`)
              "
              max-height="35%"
              max-width="35%"
              class="mb-4 mt-10"
            />
          </v-layout>
          <v-layout justify-center>
            <v-card-title class="title font-weight-regular mb-2"
              >You do not have permission to access this page</v-card-title
            >
          </v-layout>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
};
</script>
